import React from 'react';
import CardItem from '../Cards/CardItem';
import '../Cards/Cards.css';

function Portfolio() {
    return (
        <div className='cards'>
        <h1>¡Échale un vistazo a algunos de mis trabajos!</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem
                src='assets/images/img-1.jpg'
                text='Un simple reproductor de música para escuchar las canciones y covers de Ane Gómez - React Native.'
                label='Android APP'
                path='/portfolio'
              />
              <CardItem
                src='assets/images/img-2.jpg'
                text='Miniaturas, logos, banners y edición de video en el canal de Ane Gómez y en @songsbyane- Adobe Photoshop|Premiere Pro.'
                label='YT Channel'
                path='/portfolio'
              />
            </ul>
            <ul className='cards__items'>
            <CardItem
                src='assets/images/img-8.jpg'
                text='Página web de Songsbyane - HTML5|CSS3.'
                label='Website'
                path='/portfolio'
              />
            <CardItem
                src='assets/images/img-7.jpg'
                text='Página web de Usurbil 1936 - Wordpress|PHP.'
                label='Website'
                path='/portfolio'
              />
              <CardItem
                src='assets/images/img-6.jpg'
                text='Página web para el grupo de inmobiliarias Nac Premier - Wordpress|PHP'
                label='Website'
                path='/portfolio'
              />
            </ul>
            <ul className='cards__items'>
            <CardItem
                src='assets/images/img-5.jpg'
                text='Aplicación para WildForest - React Native|MongoDB.'
                label='Android APP'
                path='/portfolio'
              />
            <CardItem
                src='assets/images/img-4.jpg'
                text='Página web de GoodFoodTalk - HTML5|CSS3.'
                label='Website'
                path='/portfolio'
              />
              <CardItem
                src='assets/images/img-3.jpg'
                text='Web K-BERRI BEAUTY con multi-idioma, sesiones, sección de productos y carrito de la compra - PHP|MySQL'
                label='Website'
                path='/portfolio'
              />
            </ul>
            <ul className='cards__items'>  
              <CardItem
                src='assets/images/img-proximamente.jpg'
                text='Próximamente'
                label=''
                path='/portfolio'
              />
            </ul>
          </div>
        </div>
      </div>

    );
}
export default Portfolio;