import React from 'react';
import 'animate.css';
import { Grid, Box } from '@material-ui/core';

import './AboutMe.css';

export default function AboutMe() {
    return (
      <>
        <section className='about'>
          <Grid container>
            <Grid className='about_information_grid animate__animated animate__slideInLeft' item xs={12} md={6}>
              <Box className='about_information_box'>
                <Box className='about_information_text'>
                  <h1>sobre mi.</h1>
                  <h2>Soy un desarrollador Frontend que vive en Donostia-San Sebastian.</h2>
                  <p>Desde 2020, disfruto diseñando páginas web con diseños simples e intuitivos. Si no me encuentras programando probablemente esté trabajando en mis acuarios o haciendo calistenia. </p>
                </Box>
              </Box>
            </Grid>
            <Grid className='about_information_grid animate__animated animate__slideInRight' item xs={12} md={6}>
              <Box>
                <Box className='avatar'>
                  <img 
                    src='assets/images/about/avatar.jpg'
                    alt=''
                    width={'100%'}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          
          <Grid container>
            <Grid className='carousel animate__animated animate__slideInUp'>
              <Box className='element'>
              <img 
                  src='assets/images/about/1.jpg' 
                  alt=''
                />
              </Box>
              <Box className='element'>
              <img 
                  src='assets/images/about/2.jpg' 
                  alt=''
                />
              </Box>
              <Box className='element'>
              <img 
                  src='assets/images/about/3.jpg' 
                  alt=''
                />
              </Box>
              <Box className='element'>
              <img 
                  src='assets/images/about/4.jpg' 
                  alt=''
                />
              </Box>
              <Box className='element'>
              <img 
                  src='assets/images/about/5.jpg' 
                  alt=''
                />
              </Box>
              <Box className='element'>
              <img 
                  src='assets/images/about/6.jpg' 
                  alt=''
                />
              </Box>
            </Grid>
          </Grid>

          <hr className='separator'></hr>

          <Grid container className='about_hobbies_container'>
            <Grid className='about_hobbies_grid animate__animated animate__slideInLeft' item xs={12} md={6}>
              <Box>
                <Box className='yoda'>
                  <img 
                    src='assets/images/about/yoda.png'
                    alt=''
                    width={'100%'}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid className='about_hobbies_grid animate__animated animate__slideInRight' item s={12} md={6}>
              <Box className='about_hobbies_text'>
                <h1>aficiones.</h1>
                  <li>Cinéfilo empedernido</li>
                  <li>¿Rock de los 80 y 90? Sí, por favor</li>
                  <li>Practico calistenia y cuando llueve hago pesas</li>
                  <li>Maniático del orden</li>
                  <li>La cacuariofilia es mi pasión</li>
                  <li>Adicto a <a className='twlink' href='https://twitter.com/realnameivan' target="_blank">Twitter</a></li>
              </Box>
            </Grid>
          </Grid>

          <hr className='separator'></hr>

        </section>
      </>
    )
}